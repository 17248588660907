import React from 'react'
import Proptypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Logo = ({ logo, name, path, center, big, onClick }) => (
  <Container center={center}>
    <LinkItem to={path} onClick={onClick}>
      {
        !logo
          ? (<Title>{name}</Title>)
          : (<Image src={logo} big={big} alt={name} />)
      }
    </LinkItem>
  </Container>
)

Logo.propTypes = {
  logo: Proptypes.string,
  name: Proptypes.string,
  path: Proptypes.string,
  center: Proptypes.bool,
  big: Proptypes.bool,
  onClick: Proptypes.func
}

const Container = styled.div`
  width: 100%;
`
const Title = styled.h1`
  margin-top: 10px;
  color: ${props => props.theme.color};
`

const LinkItem = styled(Link)`
  width: 100%;
`

const Image = styled.img`
  max-height: ${({ big }) => big ? '58px' : '42px'};
  max-width: ${({ big }) => big ? '154px' : '112px'};
`

export default Logo
