import React from 'react'
import PropTypes from 'prop-types'
import { PinPad } from '../Pinpad'
import { ButtonsContainer, Container, TryAgainContainer } from './styles'
import Label from '../Label/Label'
import { darkerGray, bigText, normalText } from '../../Styles/settings/Constants'
import { Modal } from '../Modal/Modal'
import Button from '../Button/ButtonDefault'
import { maskEmail } from '../../Utils/Utils'

const MFA = ({ email, length, error, pin, setPin, isLoading, handleSubmit, handleCancel, timer, handleReSend, isSidebar = false }) => {
  return (
    <Modal styleProps={{ maxWidth: 'fit-content', height: 'auto', left: '50%' }} isSidebar={isSidebar}>
      <Container>
        <Label fontSize={bigText}><strong>Autenticação de Dois Fatores</strong></Label>
        <br />
        <Label
          fontSize={normalText}
          color={darkerGray}>
            Digite o código enviado para o email <strong>{maskEmail(email)}</strong>
        </Label>
        <PinPad
          type='numeric'
          digits={length}
          variant={error ? 'danger' : 'primary'}
          error={(error && error.message) || ''}
          initialValue={pin || ''}
          onChange={setPin}
        />
        <TryAgainContainer>
          {timer > 0 ? (
            <Label fontSize={normalText} color={darkerGray}>
              Aguarde <strong>{timer}s</strong> para solicitar novo código
            </Label>
          ) : (
            <Label fontSize={normalText} color={darkerGray}>
              Não recebeu?{' '}
              <Button link widthAuto onClick={handleReSend}>
                Enviar novo código
              </Button>
            </Label>
          )}
        </TryAgainContainer>
        <br />
        <br />
        <ButtonsContainer>
          <Button inversed onClick={handleCancel}>Cancelar</Button>
          <Button onClick={handleSubmit} disabled={(pin?.length < length) || error} isLoading={isLoading}>Confirmar</Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  )
}

MFA.propTypes = {
  length: PropTypes.number,
  setPin: PropTypes.func.isRequired,
  error: PropTypes.object,
  pin: PropTypes.string,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  timer: PropTypes.number,
  handleReSend: PropTypes.func,
  email: PropTypes.string,
  isSidebar: PropTypes.bool
}

export { MFA }
