import styled from 'styled-components'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import React from 'react'

const LabelLoader = ({ height, ...props }) => {
  const sizeHeight = () => {
    switch (height) {
    case 'small':
      return '12'
    case 'medium':
      return '14'
    case 'big':
      return '18'
    case 'large':
      return '24'
    default:
      return '12'
    }
  }

  return (
    <Wrapper
      width={180}
      height={30}
      viewBox='0 0 180 30'
      speed={4}
      backgroundColor='#F4F4F4'
      foregroundColor='#DCDCDC'
      {...props}
    >
      <rect x='0' y='0' rx='5' ry='5' width='170' height={sizeHeight()} />
    </Wrapper>
  )
}

const Wrapper = styled(ContentLoader)`
  width: 100%;
  height: 100%;
`

LabelLoader.propTypes = {
  height: PropTypes.oneOf(['small', 'medium', 'big', 'large'])
}

export default LabelLoader
