import React from 'react'
import ContentLoader from 'react-content-loader'

export const KPIsLoader = () => (
  <ContentLoader
    speed={4}
    width={140}
    height={18}
    viewBox='0 0 140 18'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    style={{ width: '100%' }}
  >
    <rect x='0' y='0' rx='5' ry='5' width='135' height='18' />
  </ContentLoader>
)
