import styled from 'styled-components'
import { smallMargin } from '../../Styles/settings/Constants'

export const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span {
    margin-bottom: 10px;
  }
`

export const TryAgainContainer = styled.div`
  margin-top: ${smallMargin};
  display: flex;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;

  > button {
    margin-right: 10px;
  }
`
